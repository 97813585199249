import React from 'react';
import Link from 'next/link';
import Head from 'next/head';
import Script from 'next/script'
import {Button, Field, IconPlusSmall, Stack, Box} from 'degen'; 

import {useWeb3} from '@/hooks/useWeb3';
import {addEllipsis} from '@/utils/string';
import routes from '@/routes';
import {withPublicLayout} from '@/layouts';
import {PageContent, PageHeading, PostsList} from '@/components';

const Home = () => {
  const {address} = useWeb3();
  return (
    <> 
      <div className="container">
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-C8K69KZCV7" />
            <Script id="google-analytics">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
       
                gtag('config', 'G-C8K69KZCV7');
              `}
            </Script>
      </div>
      <PageContent>  
        <Stack space="0" direction="vertical">
          <Box paddingBottom="10">  
              <p className="headingLarge top-minus-10">NGO-E-2018</p> 
              <p className="heading just">The fondation trust is dedicated to local culture. With the central question, ’What defines local culture?’, we think about local stores as open doors for thought that develop local trust through their work.</p>
              <p className="heading just">fondation trustは、ローカルカルチャーに特化しています。地域文化を定義するものは何か」という問いを中心に、私たちは地域店舗を、その仕事を通じて地域の信頼を発展させる思考の開かれた扉として考えています。</p>
              <p className="heading just">La fondation trust est dédiée à la culture locale. Avec la question centrale "Qu'est-ce qui définit la culture locale?", nous considérons les magasins locaux comme des portes ouvertes à la réflexion qui développent la confiance locale par leur travail.</p>
              <p className="heading just">fondation trust er dedikeret til lokal kultur. Med det centrale spørgsmål, "Hvad definerer lokal kultur?", tænker vi på lokale butikker som åbne døre for tanker, der udvikler lokal tillid gennem deres arbejde.</p>  
          </Box>  
          {/*LIMIT CREATION TO MY WALLET*/}
          <Link href={address == "0x817fdf2B4Df7b70bEC4eCEAC7D80FA4Ad3179BBE" ? routes.entries.create : ""} passHref>
            <Button>
              <p className="heading bottom-40">Create</p>
            </Button> 
          </Link>
          <PostsList address="0x817fdf2B4Df7b70bEC4eCEAC7D80FA4Ad3179BBE"/>
          <p className="subheading background">Current writers: 0x817...79BBE</p> 
        </Stack>
      </PageContent>
    </>
  );
};

export default withPublicLayout(Home);